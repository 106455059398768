import React from 'react'
import styles from './Popup.module.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
class Popup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.setState({
      ...this.state
    })
  }

  render() {
    return (
      <>
        {this.props.showPopup &&
          <div
            className={styles.Popup}
            style={
              {
                opacity: this.props.showPopup ? 1 : 0
              }
            }
          >
            <div className={styles.PopupContainer}>
              <div className={styles.CloseBtnContainer} onClick={this.props.closePopup}>
                <FontAwesomeIcon icon={faTimes} size="2x" color="#FFF" />
              </div>
              {this.props.isVideo ?
                <>
                  <div className={styles.PopupBluredVideoContainer}>
                    <video style={{ objectFit: 'cover' }} className={styles.PopupBluredVideo} autoPlay loop muted>
                      <source src={this.props.popupImage} type="video/mp4" />
                    </video>
                  </div>
                  <div className={styles.container}>
                    <video style={{ objectFit: 'cover' }} className={styles.PopupVideo} autoPlay loop muted>
                      <source src={this.props.popupImage} type="video/mp4" />
                    </video>
                    <div className={styles.contentContainer}>
                      {this.props.popupContent ?
                        <>
                          <h2>{this.props.popupContent.title}</h2>
                          <div className={styles.TagContainer}>
                            {
                              this.props.popupContent.tags.map((tag, index) =>  
                              <div className={styles.Tag} key={index}>{tag}</div>
                              )
                            }
                          </div>
                          <p>{this.props.popupContent.paragraph}</p>
                          <a href={this.props.popupContent.link} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faGithub} size="lg" />
                          </a>
                        </>
                        :
                        <></>
                      }
                    </div>
                  </div>
                </>
                :
                <>
                  <img src={this.props.popupImage} className={styles.PopupBluredImageLeft} />
                  {/* <div className={styles.container}> */}
                  {this.props.popupContent ?
                    <div className={styles.horizontalContainer}>
                      <img src={this.props.popupImage} className={styles.popupImage} />
                      <div className={styles.contentContainer}>
                        {this.props.popupContent ?
                          <>
                            <h2>{this.props.popupContent.title}</h2>
                            <div className={styles.TagContainer}>
                              {
                                this.props.popupContent.tags.map((tag, index) => 
                                <div className={styles.Tag} key={index}>{tag}</div>
                                )
                              }
                            </div>
                            <p>{this.props.popupContent.paragraph}</p>
                            <a href={this.props.popupContent.link} target="_blank" rel="noreferrer">
                              <FontAwesomeIcon icon={faGithub} size="lg" />
                            </a>
                          </>
                          :
                          <></>
                        }
                      </div>
                    </div>
                    :
                    <img src={this.props.popupImage} className={styles.popupImage} />
                  }

                  {/* <div className={styles.contentContainer}>
                      dsfg
                    </div>
                  </div> */}
                  <img src={this.props.popupImage} className={styles.PopupBluredImageRight} />
                </>
              }
            </div>
          </div>
        }
      </>
    )
  }
}

Popup.propTypes = {
  showPopup: PropTypes.bool,
  popupImage: PropTypes.object,
  closePopup: PropTypes.func,
  isVideo: PropTypes.bool,
  popupContent: PropTypes.object,
};

Popup.defaultProps = {};

export default Popup;