import React from 'react';
import styles from './ThreeD.module.css';
import * as THREE from 'three';
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls';
import {
  MTLLoader
} from 'three/examples/jsm/loaders/MTLLoader';
// import {
//   OBJLoader
// } from 'three/examples/jsm/loaders/OBJLoader';
import {
  FBXLoader
} from 'three/examples/jsm/loaders/FBXLoader'
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';
import PropTypes from 'prop-types';
import * as BufferGeometryUtils from './components/bufferGeometryUtils/BufferGeometryUtils';

class ThreeD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scene: new THREE.Scene(),
      allowScrollEffect: true,
      t: 0,
      pageHeight: this.props.pageHeight,
      mouseNormalizedPosition: this.props.mouseNormalizedPosition,
      reset3DObjectsPosition: this.props.reset3DObjectsPosition,
      scrollIndex: this.props.scrollIndex,
      animationLock: false,
      animationFired: false,
      direction: 'up',
      prev: undefined,
      stopAnimations: false,
      rocketPositionYBeforeFlyToHorizon: 0,
      rocketRotationDegBeforeAnimation: 0,
      objectsLoaded: 0,
      contactBtnClick: this.props.contactBtnClick
    };
    this.rocketFlyToHorizon = this.rocketFlyToHorizon.bind(this);
    this.rocketXPositionFromPageWidth = this.rocketXPositionFromPageWidth.bind(this);
    this.cloudsYPositionFromPageHeight = this.cloudsYPositionFromPageHeight.bind(this);
    this.handleObjectLoaded = this.handleObjectLoaded.bind(this);
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.clock = new THREE.Clock();
    this.cameraTarget = new THREE.Mesh(new THREE.BoxGeometry());
    this.mixers = [];
    this.camera.position.setZ(25);
    this.camera.position.setY(1.3);
    this.camera.position.setX(0);
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2()

    this.fBXLoaderRes = (object) => {
      object.traverse(function (child) {
        child.castShadow = true;
        child.receiveShadow = false;
      });
      object.position.y = -0.6;
      object.position.x = this.rocketXPositionFromPageWidth();
      object.position.z = 13;
      object.scale.set(0.5, 0.5, 0.5);
      object.name = "rocket";
      object.castShadow = true;
      object.receiveShadow = true;
      let scene = this.state.scene.add(object);
      this.setState({ scene: scene });
    };

    this.objectLoaderRes = (object) => {
      object.position.y = 0.1;
      object.position.x = 0;
      object.position.z = 0.1;
      object.scale.set(0.5, 0.5, 0.5)
      object.castShadow = true;
      object.name = "landscape";
      let scene = this.state.scene.add(object);
      this.setState({
        scene: scene
      });
      object.traverse(function (object) {
        if (object.isMesh) object.castShadow = true;
      });
    }

    this.gltfLoaderRes = (gltf) => {
      gltf.scene.position.set(this.rocketXPositionFromPageWidth(), -0.3, 13.1);
      gltf.scene.rotateX(THREE.Math.degToRad(180));
      gltf.scene.scale.set(3, 3, 3);
      gltf.scene.name = "fire";
      this.state.scene.add(gltf.scene);
      let mixer = new THREE.AnimationMixer(gltf.scene);
      gltf.animations.forEach((clip) => {
        mixer.clipAction(clip).play();
      });
      this.mixers.push(mixer);
    };

    this.gltfJerrycanLoaderRes = (gltf) => {
      let mesh = gltf.scene.children[0].children[0].children[0].children[0].children[0].children[0];
      gltf.scene.position.set(this.rocketXPositionFromPageWidth() + 2, 0.4, 8.1);
      gltf.scene.rotateY(THREE.Math.degToRad(240));
      gltf.scene.scale.set(0.4, 0.4, 0.4);
      gltf.scene.name = "jerrycan";
      mesh.castShadow = true;
      mesh.receiveShadow = false;
      mesh.material.color = new THREE.Color(0xff1500);
      this.state.scene.add(gltf.scene);
    };

    this.gltfSecondJerrycanLoaderRes = (gltf) => {
      let mesh = gltf.scene.children[0].children[0].children[0].children[0].children[0].children[0];
      gltf.scene.position.set(this.rocketXPositionFromPageWidth() + 5, -0.3, 10.1);
      gltf.scene.rotateZ(THREE.Math.degToRad(90));
      gltf.scene.rotateX(THREE.Math.degToRad(220));
      gltf.scene.scale.set(0.4, 0.4, 0.4);
      gltf.scene.name = "jerrycan";
      mesh.castShadow = true;
      mesh.receiveShadow = false;
      mesh.material.color = new THREE.Color(0xff1500);
      this.state.scene.add(gltf.scene);
    };

    this.gltfCloudsLoaderRes = (gltf, positionX, positionZ, name, positionYOffset) => {
      gltf.scene.position.set(positionX, positionYOffset ? this.cloudsYPositionFromPageHeight() + positionYOffset : this.cloudsYPositionFromPageHeight(), positionZ);
      // gltf.scene.scale.set(3, 3, 3);
      gltf.scene.name = name;
      this.state.scene.add(gltf.scene);
    };

    this.textureLoaderRes = () => {
      var mesh = new THREE.Mesh(this.geometry, this.material);
      mesh.position.set(window.innerWidth / 8, 0, -70);
      mesh.name = "sky";
      this.state.scene.add(mesh);
    };

    this.skyLineTextureLoaderRes = () => {
      var mesh = new THREE.Mesh(this.citySkylineGeometry, this.citySkylineMaterial);
      mesh.position.set(0, 13, -45);
      mesh.name = "skyline";
      this.state.scene.add(mesh);
    };

    this.skyLine2TextureLoaderRes = () => {
      var mesh = new THREE.Mesh(this.citySkyline2Geometry, this.citySkyline2Material);
      mesh.position.set(0, 18, -55);
      mesh.name = "skyline2";
      this.state.scene.add(mesh);
    };

    this.skyLine3TextureLoaderRes = () => {
      var mesh = new THREE.Mesh(this.citySkyline3Geometry, this.citySkyline3Material);
      mesh.position.set(0, 19, -65);
      mesh.name = "skyline3";
      this.state.scene.add(mesh);
    };

    this.moonTextureLoaderRes = () => {
      var mesh = new THREE.Mesh(this.moonGeometry, this.moonMaterial);
      mesh.position.set(50, 400, 50);
      mesh.rotateX(Math.PI / 2);
      mesh.name = "moon";
      this.state.scene.add(mesh);
    };

    this.groundTextureLoaderRes = () => {
      const ground = new THREE.Mesh(this.groundGeometry, this.groundMaterial);
      ground.receiveShadow = true;
      ground.position.set(0, -0.6, 20);
      ground.rotateX(-Math.PI / 2);
      ground.name = "ground";
      this.state.scene.add(ground);
    };

    this.moveCamera = (e) => {
      const fireObj = this.state.scene.getObjectByName("fire");
      const rocketObj = this.state.scene.getObjectByName("rocket");
      const spotLightObj = this.state.scene.getObjectByName("spotlight");
      const moonSpotLightObj = this.state.scene.getObjectByName("moonSpotlight");
      const moonObj = this.state.scene.getObjectByName("moon");
      this.setState({
        ...this.state,
        allowScrollEffect: false
      });
      const window = e.currentTarget;
      let delta = this.state.prev - window.scrollY;
      let direction = 'up';
      if (!this.state.prev) {
        delta = 0;
      } else if (delta == 0) {
        delta = 1;
      } else if (isFinite(delta / 200)) {
        delta = (this.state.prev - window.scrollY) / 200;
        if (this.state.prev - window.scrollY < 0) {
          direction = 'down';
        }
      }

      if (this.state.direction !== direction) {
        this.setState({
          ...this.state,
          direction: direction,
          animationFired: false,
          t: 0
        });
      }
      if (!this.state.animationLock && !this.state.animationFired) {
        if (window.scrollY < this.props.pageHeight / 32 * 19 && window.scrollY > this.props.pageHeight / 32 * 16 && direction === 'up') {
          this.setState({
            ...this.state,
            animationLock: true,
            animationFired: true
          });
          this.moveRocketObjectRightOutOfScreen();
        } else if (window.scrollY >= this.props.pageHeight / 32 * 7.5 && window.scrollY < this.props.pageHeight / 32 * 8.5 && direction === 'down') {
          this.setState({
            ...this.state,
            animationLock: true,
            animationFired: true
          });
          this.moveRocketObjectRightOutOfScreen();
        } else if (window.scrollY >= this.props.pageHeight / 32 * 17 && window.scrollY < this.props.pageHeight / 32 * 20 && direction === 'down') {
          this.setState({
            ...this.state,
            animationLock: true,
            animationFired: true
          });
          this.moveRocketObjectLeftOutOfScreen();
        } else if (window.scrollY < this.props.pageHeight / 32 * 10 && window.scrollY > this.props.pageHeight / 32 * 9 && direction === 'up') {
          this.setState({
            ...this.state,
            animationLock: true,
            animationFired: true
          });
          this.moveRocketObjectLeftOutOfScreen();
        }
      }
      if (window.scrollY < this.props.pageHeight / 32 * 15.5 && window.scrollY > this.props.pageHeight / 32 * 14.5) {
        this.setState({
          ...this.state,
          animationFired: false
        });
      }
      if (window.scrollY < this.props.pageHeight / 32 * 23 && window.scrollY > this.props.pageHeight / 32 * 19.5) {
        this.setState({
          ...this.state,
          animationFired: false
        });
      }
      if (window.scrollY < this.props.pageHeight / 32 * 28.5 && window.scrollY > this.props.pageHeight / 32 * 27.5 && direction === 'up') {
        this.moveRocketObjectRight();
      } else if (window.scrollY >= this.props.pageHeight / 32 * 23.5 && window.scrollY < this.props.pageHeight / 32 * 24.5 && direction === 'down') {
        this.moveRocketObjectLeft();
      }
      if (!this.state.animationLock && !this.state.animationFired) {
        if (window.scrollY <= this.props.pageHeight / 32 * 28.5 && window.scrollY > this.props.pageHeight / 32 * 28 && direction === 'up') {
          this.setState({
            animationLock: true,
            animationFired: true,
            rocketRotationDegBeforeAnimation: rocketObj.rotation.y
          });
          this.rocketRotationLoopYAxis();
        } else if (window.scrollY >= this.props.pageHeight / 32 * 23 && window.scrollY < this.props.pageHeight / 32 * 23.5 && direction === 'down') {
          this.setState({
            animationLock: true,
            animationFired: true,
            rocketRotationDegBeforeAnimation: rocketObj.rotation.y
          });
          this.rocketRotationLoopYAxis();
        }
      }
      if (window.scrollY <= this.props.pageHeight / 32) {
        this.setState({ t: 0 });
        this.rocketFlyToHorizon(e);
      }
      this.setState({ prev: window.scrollY });

      if (fireObj && rocketObj && ((rocketObj.position.y >= -0.6 && delta < 0) || delta > 0) && spotLightObj && moonObj && moonSpotLightObj) {
        let offset = new THREE.Vector3(0, this.cameraTarget.position.y, Math.round(25));
        fireObj.position.setY(fireObj.position.y + delta);
        if (window.scrollY > this.props.pageHeight / 32) {
          this.camera.position.lerp(offset, 0.3);
        }
        rocketObj.position.setY(rocketObj.position.y + delta);
        spotLightObj.target = rocketObj;
        moonSpotLightObj.target = moonObj;
        this.cameraTarget.position.setY(this.cameraTarget.position.y + delta);
      }
    };

    this.moveRocketObjectRight = () => {
      const step = 0.02;
      const rocketObj = this.state.scene.getObjectByName("rocket");
      const fireObj = this.state.scene.getObjectByName("fire");
      if (rocketObj && fireObj) {
        if (rocketObj.position.x >= -this.rocketXPositionFromPageWidth()) {
          rocketObj.position.x = -this.rocketXPositionFromPageWidth();
          fireObj.position.x = -this.rocketXPositionFromPageWidth();
          return;
        }
        rocketObj.position.x += step;
        fireObj.position.x += step;
      }
      requestAnimationFrame(() => this.moveRocketObjectRight());
    }

    this.moveRocketObjectLeft = () => {
      const step = 0.02;
      const rocketObj = this.state.scene.getObjectByName("rocket");
      const fireObj = this.state.scene.getObjectByName("fire");
      if (rocketObj && fireObj) {
        if (rocketObj.position.x <= this.rocketXPositionFromPageWidth()) {
          rocketObj.position.x = this.rocketXPositionFromPageWidth();
          fireObj.position.x = this.rocketXPositionFromPageWidth();
          return;
        }
        rocketObj.position.x -= step;
        fireObj.position.x -= step;
      }
      requestAnimationFrame(() => this.moveRocketObjectLeft());
    }

    this.moveRocketObjectRightOutOfScreen = (stop = false) => {
      const fireObj = this.state.scene.getObjectByName("fire");
      const rocketObj = this.state.scene.getObjectByName("rocket");
      if (rocketObj && fireObj) {
        const step = 0.25;
        const rotationStep = 1 / (2 * 60); // 1 / (tau * fps)
        const finalAngle = Math.PI * 2;
        const angleStep = finalAngle * rotationStep;
        rocketObj.rotation.y += angleStep;
        rocketObj.position.y += step;
        fireObj.position.y += step;
        if (this.cameraTarget.position.y + 10 < rocketObj.position.y) {
          rocketObj.position.y -= 20;
          rocketObj.position.x = this.rocketXPositionFromPageWidth();
          fireObj.position.y -= 20;
          fireObj.position.x = this.rocketXPositionFromPageWidth();
          stop = true;
        }
        if (stop && this.cameraTarget.position.y - 1.5 > rocketObj.position.y && this.cameraTarget.position.y - 1.8 < rocketObj.position.y) {
          this.setState({ animationLock: false });
          return;
        }
        requestAnimationFrame(() => this.moveRocketObjectRightOutOfScreen(stop));
      }
    }

    this.moveRocketObjectLeftOutOfScreen = (stop = false) => {
      const fireObj = this.state.scene.getObjectByName("fire");
      const rocketObj = this.state.scene.getObjectByName("rocket");
      if (rocketObj && fireObj) {
        const step = 0.25;
        const rotationStep = 1 / (2 * 60); // 1 / (tau * fps)
        const finalAngle = Math.PI * 2;
        const angleStep = finalAngle * rotationStep;
        rocketObj.rotation.y -= angleStep;
        rocketObj.position.y += step;
        fireObj.position.y += step;
        if (this.cameraTarget.position.y + 10 < rocketObj.position.y) {
          rocketObj.position.y -= 20;
          rocketObj.position.x = -this.rocketXPositionFromPageWidth();
          fireObj.position.y -= 20;
          fireObj.position.x = -this.rocketXPositionFromPageWidth();
          stop = true;
        }
        if (stop && this.cameraTarget.position.y - 1.5 > rocketObj.position.y && this.cameraTarget.position.y - 1.8 < rocketObj.position.y) {
          this.setState({
            animationLock: false,
            rocketPositionYBeforeFlyToHorizon: rocketObj.position.y
          });
          return;
        }
        requestAnimationFrame(() => this.moveRocketObjectLeftOutOfScreen(stop));
      }
    }

    this.rocketRotationLoopYAxis = () => {
      const step = 1 / (4 * 60); // 1 / (tau * fps)
      const rocketObj = this.state.scene.getObjectByName("rocket");
      var finalAngle = Math.PI * 4;
      if (rocketObj) {
        //finalAngle = this.state.rocketRotationDegBeforeAnimation + Math.PI * 4;
        const angleStep = finalAngle * step;
        if ((this.state.rocketRotationDegBeforeAnimation + Math.PI * 4 <= rocketObj.rotation.y && this.state.direction === 'up') || (this.state.rocketRotationDegBeforeAnimation - Math.PI * 4 >= rocketObj.rotation.y && this.state.direction === 'down')) {
          this.setState({ animationLock: false });
          return;
        }
        if (this.state.direction === 'up') {
          rocketObj.rotation.y += angleStep;
        } else {
          rocketObj.rotation.y -= angleStep;
        }
        requestAnimationFrame(() => this.rocketRotationLoopYAxis());
      }
    }

    this.animate = () => {
      requestAnimationFrame(this.animate);
      var delta = this.clock.getDelta();
      if (this.mixers && this.mixers.length) {
        for (let i = 0; i < this.mixers.length; ++i) {
          this.mixers[i].update(delta);
        }
      }
      const rocketObj = this.state.scene.getObjectByName("rocket");
      if (rocketObj && this.state.prev && this.state.prev < (this.props.pageHeight - document.documentElement.clientHeight)) {
        rocketObj.rotation.y += 0.003;
      }
      this.controls.update();
      this.camera.lookAt(this.cameraTarget.position);
      this.renderer.render(this.state.scene, this.camera);
    }

    this.setStarPosition = (geometry) => {
      const [x, y, z] = Array(3)
        .fill()
        .map(() => THREE.MathUtils.randFloatSpread(100));
      geometry.translate(x, y + 100 + this.rocketYPositionsFromPageHeight(4), z);
    };

    this.cameraTarget.visible = false;
    this.cameraTarget.position.set(0, 1.3, 13.1);
    this.state.scene.add(this.cameraTarget);
    this.textureLoader = new THREE.TextureLoader();
    this.geometry = new THREE.PlaneGeometry(window.innerWidth * 2, window.innerHeight / 2);
    this.material = new THREE.MeshLambertMaterial({
      transparent: true,
      castShadow: false,
      receiveShadow: false,
      map: this.textureLoader.load('img/background.png', (object) => this.textureLoaderRes(object))
    });
    this.skylineTextureLoader = new THREE.TextureLoader();
    this.citySkylineGeometry = new THREE.PlaneGeometry(260, 28);
    this.citySkylineMaterial = new THREE.MeshLambertMaterial({
      transparent: true,
      castShadow: true,
      receiveShadow: true,
      map: this.skylineTextureLoader.load('img/city-skyline1.png', (object) => this.skyLineTextureLoaderRes(object))
    });
    this.skyline2TextureLoader = new THREE.TextureLoader();
    this.citySkyline2Geometry = new THREE.PlaneGeometry(290, 38);
    this.citySkyline2Material = new THREE.MeshLambertMaterial({
      transparent: true,
      castShadow: true,
      receiveShadow: true,
      map: this.skylineTextureLoader.load('img/city-skyline2.png', (object) => this.skyLine2TextureLoaderRes(object))
    });

    this.skyline3TextureLoader = new THREE.TextureLoader();
    this.citySkyline3Geometry = new THREE.PlaneGeometry(300, 40);
    this.citySkyline3Material = new THREE.MeshLambertMaterial({
      transparent: true,
      castShadow: true,
      receiveShadow: true,
      map: this.skylineTextureLoader.load('img/city-skyline3.png', (object) => this.skyLine3TextureLoaderRes(object))
    });
    this.moonTextureLoader = new THREE.TextureLoader();
    this.moonGeometry = new THREE.PlaneGeometry(200, 200);
    this.moonMaterial = new THREE.MeshLambertMaterial({
      transparent: true,
      castShadow: true,
      receiveShadow: true,
      map: this.moonTextureLoader.load('img/moon.png', (object) => this.moonTextureLoaderRes(object))
    });
    this.groundGeometry = new THREE.PlaneGeometry(500, 500);
    this.groundMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff
    });
  }

  rocketXPositionFromPageWidth(nextProps) {
    var rocketPosition;
    if (nextProps) {
      if (nextProps.pageWidth <= 1024) {
        rocketPosition = -8;
      } else if (nextProps.pageWidth <= 1366 && nextProps.pageWidth > 1024) {
        rocketPosition = -8;
      } else if (nextProps.pageWidth <= 1440 && nextProps.pageWidth > 1366) {
        rocketPosition = -8;
      } else if (nextProps.pageWidth <= 1536 && nextProps.pageWidth > 1440) {
        rocketPosition = -8.5;
      } else if (nextProps.pageWidth <= 1920 && nextProps.pageWidth > 1536) {
        rocketPosition = -10;
      } else if (nextProps.pageWidth <= 2550 && nextProps.pageWidth > 1920) {
        rocketPosition = -8;
      } else if (nextProps.pageWidth > 2550) {
        rocketPosition = -9.5;
      }
      return rocketPosition
    } else if (this.props.pageWidth) {
      if (this.props.pageWidth <= 1024) {
        rocketPosition = -8;
      } else if (this.props.pageWidth <= 1366 && this.props.pageWidth > 1024) {
        rocketPosition = -8;
      } else if (this.props.pageWidth <= 1440 && this.props.pageWidth > 1366) {
        rocketPosition = -8;
      } else if (this.props.pageWidth <= 1536 && this.props.pageWidth > 1440) {
        rocketPosition = -8.5;
      } else if (this.props.pageWidth <= 1920 && this.props.pageWidth > 1536) {
        rocketPosition = -10;
      } else if (this.props.pageWidth <= 2550 && this.props.pageWidth > 1920) {
        rocketPosition = -8;
      } else if (this.props.pageWidth > 2550) {
        rocketPosition = -9.5;
      }
      return rocketPosition
    }
    return 0;
  }

  rocketYPositionsFromPageHeight(page) {
    var rocketPositions;
    if (window.innerHeight) {
      if (window.innerHeight < 640 && window.innerHeight >= 600) {
        rocketPositions = [26.15, 46.15, 70.5];
      } else if (window.innerHeight < 720 && window.innerHeight >= 640) {
        rocketPositions = [28.15, 50.85, 76.75];
      } else if (window.innerHeight < 768 && window.innerHeight >= 720) {
        rocketPositions = [31.65, 59.6, 88.95];
      } else if (window.innerHeight < 820 && window.innerHeight >= 768) {
        rocketPositions = [33.9, 64.85, 95.35];
      } else if (window.innerHeight < 860 && window.innerHeight >= 820) {
        rocketPositions = [36.15, 69.1, 101.85];
      } else if (window.innerHeight < 900 && window.innerHeight >= 860) {
        rocketPositions = [37.9, 72.6, 106.85];
      } else if (window.innerHeight < 969 && window.innerHeight >= 900) {
        rocketPositions = [39.65, 75.85, 110.8];
      } else if (window.innerHeight < 1080 && window.innerHeight >= 969) {
        rocketPositions = [42.9, 80.85, 120.6];
      } else if (window.innerHeight < 1080 && window.innerHeight >= 1024) {
        rocketPositions = [45.4, 86.6, 127.35];
      } else if (window.innerHeight < 1329 && window.innerHeight >= 1080) {
        rocketPositions = [47.9, 91.35, 134.35];
      } else if (window.innerHeight >= 1329) {
        rocketPositions = [59.15, 112.35, 165.6];
      }
      return rocketPositions[page - 3];
    }
    return 0;
  }


  cloudsYPositionFromPageHeight() {
    if (window.innerHeight) {
      var cloudsPosition = 0;
      if (window.innerHeight <= 768) {
        cloudsPosition = 40;
      } else if (window.innerHeight <= 864 && window.innerHeight > 768) {
        cloudsPosition = 42;
      } else if (window.innerHeight <= 900 && window.innerHeight > 864) {
        cloudsPosition = 44;
      } else if (window.innerHeight <= 1080 && window.innerHeight > 900) {
        cloudsPosition = 60;
      } else if (window.innerHeight > 1080) {
        cloudsPosition = 95;
      }
      return cloudsPosition
    }
    return 0;
  }

  rocketFlyToHorizon(e) {
    const step = 0.1;
    const rocketObj = this.state.scene.getObjectByName("rocket");
    let fireObj = this.state.scene.getObjectByName("fire");
    if (this.state.t >= 350 || this.state.stopAnimations) return; // Motion ended
    if (rocketObj) {
      this.setState({
        ...this.state,
        t: this.state.t + step
      });
      rocketObj.position.y += step; // Increment rotation
      if (fireObj) {
        fireObj.position.y += step;
        this.cameraTarget.position.setY(this.cameraTarget.position.y + step);
      }
      requestAnimationFrame(() => this.rocketFlyToHorizon(e));
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.mouseNormalizedPosition !== this.state.mouseNormalizedPosition) {
      this.setState({
        mouseNormalizedPosition: nextProps.mouseNormalizedPosition
      });
      this.mouse.x = nextProps.mouseNormalizedPosition.x;
      this.mouse.y = nextProps.mouseNormalizedPosition.y;
      this.raycaster.setFromCamera(this.mouse, this.camera);
      var intersects = this.raycaster.intersectObject(this.state.scene, true);
      if (intersects.length > 0 && intersects[0].object.name === 'rocket_1') {
        var width = window.innerWidth;
        var height = window.innerHeight;
        var widthHalf = width / 2;
        var heightHalf = height / 2;
        var pos = new THREE.Vector3();
        pos.setFromMatrixPosition(intersects[0].object.matrixWorld);
        pos.project(this.camera);
        pos.x = (pos.x * widthHalf) + widthHalf;
        pos.y = -(pos.y * heightHalf) + heightHalf;
        this.props.clickHandler(pos);
      }
      return true;
    }
    if (nextProps.reset3DObjectsPosition === true && nextProps.reset3DObjectsPosition !== this.state.reset3DObjectsPosition) {
      this.setState({
        stopAnimations: true,
        reset3DObjectsPosition: nextProps.reset3DObjectsPosition,
        scrollIndex: nextProps.scrollIndex,
        mouseNormalizedPosition: nextProps.mouseNormalizedPosition,
      });

      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camera.position.setZ(25);
      this.camera.position.setY(1.3);
      this.camera.position.setX(0);
      const rocketObj = this.state.scene.getObjectByName("rocket");
      const fireObj = this.state.scene.getObjectByName("fire");
      const groundObj = this.state.scene.getObjectByName("ground");
      if (fireObj && rocketObj) {
        if (isFinite(this.state.scrollIndex) && nextProps.scrollIndex === 4) {
          rocketObj.position.x = -this.rocketXPositionFromPageWidth(nextProps);
          rocketObj.position.y = this.rocketYPositionsFromPageHeight(5);
          rocketObj.position.z = 13;
          fireObj.position.x = -this.rocketXPositionFromPageWidth(nextProps);
          fireObj.position.y = this.rocketYPositionsFromPageHeight(5);
          fireObj.position.z = 13.1;
          this.cameraTarget.position.x = 0;
          this.cameraTarget.position.y = this.rocketYPositionsFromPageHeight(5) + 1;
          this.cameraTarget.position.z = 13.1;
          this.camera.position.setY(this.rocketYPositionsFromPageHeight(5));
        } else if (isFinite(this.state.scrollIndex) && nextProps.scrollIndex === 5) {
          rocketObj.position.x = this.rocketXPositionFromPageWidth(nextProps);
          rocketObj.position.y = this.rocketYPositionsFromPageHeight(5) + 300;
          rocketObj.position.z = 13;
          fireObj.position.x = this.rocketXPositionFromPageWidth(nextProps);
          fireObj.position.y = this.rocketYPositionsFromPageHeight(5) + 300;
          fireObj.position.z = 13.1;
          this.cameraTarget.position.x = 0;
          this.cameraTarget.position.y = this.rocketYPositionsFromPageHeight(5) + 300;
          this.cameraTarget.position.z = 13.1;
          this.camera.position.setY(this.rocketYPositionsFromPageHeight(5) + 25);
        } else {
          rocketObj.position.x = this.rocketXPositionFromPageWidth(nextProps);
          rocketObj.position.y = -0.6;
          rocketObj.position.z = 13;
          fireObj.position.x = this.rocketXPositionFromPageWidth(nextProps);
          fireObj.position.y = -0.3;
          fireObj.position.z = 13.1;
          this.cameraTarget.position.x = 0;
          this.cameraTarget.position.y = 1.3;
          this.cameraTarget.position.z = 13.1;
          this.camera.position.setY(1.3);
        }
        rocketObj.scale.set(0.5, 0.5, 0.5);
        fireObj.rotateX(THREE.Math.degToRad(180));
        fireObj.scale.set(3, 3, 3);
        groundObj.position.y = -0.6;
        groundObj.position.x = 0;
        groundObj.position.z = 80;
      }
      setTimeout(() => {
        this.setState({
          stopAnimations: false
        });
      }, 2000);
      return true;
    } else if (nextProps.reset3DObjectsPosition !== this.state.reset3DObjectsPosition) {
      this.setState({
        reset3DObjectsPosition: nextProps.reset3DObjectsPosition
      });
      return true;
    } else if (nextProps.pageHeight !== this.props.pageHeight) {
      this.setState({
        pageHeight: nextProps.pageHeight
      });
      return true;
    }
    return false;
  }

  handleObjectLoaded() {
    let delta = (this.rocketYPositionsFromPageHeight(4) - this.rocketYPositionsFromPageHeight(3)) / 2;

    // Clouds Lights
    this.cloudsSpotLight = new THREE.PointLight(0xffffff, 100, 100);
    this.cloudsSpotLight.intensity = 10;
    this.cloudsSpotLight.position.set(0, this.rocketYPositionsFromPageHeight(3) + delta + 100, 20);
    this.cloudsSpotLight.decay = 2;
    this.cloudsSpotLight.distance = 450;
    this.cloudsSpotLight.castShadow = true;
    this.cloudsSpotLight.shadowCameraVisible = true;
    this.cloudsSpotLight.shadow.mapSize.height = 512;
    this.cloudsSpotLight.shadow.mapSize.height = 512;
    this.cloudsSpotLight.shadow.camera.near = 10;
    this.cloudsSpotLight.shadow.camera.far = 100;
    this.cloudsSpotLight.shadowDarkness = 1000;
    this.cloudsSpotLight.shadow.focus = 0.5;
    this.cloudsSpotLight.name = `cloudsTopSpotlight`;
    this.state.scene.add(this.cloudsSpotLight);

    // Ambient Lights
    this.ambientLight.intensity = 0.1;
    this.state.scene.add(this.ambientLight);

    // Hemisphere Lights
    this.hemiLight.intensity = 2;
    this.state.scene.add(this.hemiLight);

    // Spot Lights
    this.spotLight = new THREE.PointLight(0xffffff, 1, 100);
    this.spotLight.intensity = 2;
    this.spotLight.position.set(0, 5, 25);
    this.spotLight.castShadow = true;
    this.spotLight.decay = 2;
    this.spotLight.distance = 500;
    this.spotLight.castShadow = true;
    this.spotLight.shadowCameraVisible = true;
    this.spotLight.shadow.mapSize.height = 512;
    this.spotLight.shadow.mapSize.height = 512;
    this.spotLight.shadow.camera.near = 10;
    this.spotLight.shadow.camera.far = 100;
    this.spotLight.shadowDarkness = 100;
    this.spotLight.shadow.focus = 1;
    this.spotLight.name = "spotlight";
    this.state.scene.add(this.spotLight);

    // this.spotLight = new THREE.PointLight(0xffffff, 1, 100);
    // this.spotLight.intensity = 2;
    // this.spotLight.position.set(50, 5, 10);
    // this.spotLight.castShadow = true;
    // this.spotLight.decay = 2;
    // this.spotLight.distance = 500;
    // this.spotLight.castShadow = true;
    // this.spotLight.shadowCameraVisible = true;
    // this.spotLight.shadow.mapSize.height = 512;
    // this.spotLight.shadow.mapSize.height = 512;
    // this.spotLight.shadow.camera.near = 10;
    // this.spotLight.shadow.camera.far = 1000;
    // this.spotLight.shadowDarkness = 100;
    // this.spotLight.shadow.focus = 1;
    // this.spotLight.name = "spotlight";
    // this.state.scene.add(this.spotLight);

    this.moonSpotLight = new THREE.SpotLight(0xffffff);
    this.moonSpotLight.intensity = 50;
    this.moonSpotLight.position.set(50, 350, 50);
    this.moonSpotLight.angle = -Math.PI / 2;
    this.moonSpotLight.castShadow = true;
    this.moonSpotLight.decay = 2;
    this.moonSpotLight.distance = 200;
    this.moonSpotLight.castShadow = true;
    this.moonSpotLight.shadow.mapSize.width = 512;
    this.moonSpotLight.shadow.mapSize.height = 512;
    this.moonSpotLight.shadow.camera.near = 10;
    this.moonSpotLight.shadow.camera.far = 100;
    this.moonSpotLight.shadow.focus = 1;
    this.moonSpotLight.name = "moonSpotlight";
    this.moonSpotLight.target.position.set(50, 500, 50);
    this.state.scene.add(this.moonSpotLight.target);
    this.state.scene.add(this.moonSpotLight);

    this.renderer.render(this.state.scene, this.camera);

    // Animate 
    this.animate();

    const rocketObj = this.state.scene.getObjectByName("rocket");
    var width = window.innerWidth;
    var height = window.innerHeight;
    var widthHalf = width / 2;
    var heightHalf = height / 2;
    var pos = rocketObj.position.clone();
    pos.project(this.camera);
    pos.x = (pos.x * widthHalf) + widthHalf;
    pos.y = -(pos.y * heightHalf) + heightHalf;
    this.props.rocketNormalizedPosition(pos);

    this.props.onItemsLoadingComplete();
  }

  componentDidMount() {
    this.setState({ pageHeight: this.props.pageHeight });
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.shadowMap.enabled = true;
    document.body.appendChild(this.renderer.domElement);
    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.ambientLight = new THREE.AmbientLight(0x1caad9);
    this.hemiLight = new THREE.HemisphereLight(new THREE.Color('hsl(0, 10%, 50%)'), new THREE.Color('hsl(0, 0%, 0%)'), .1);
    this.gltfLoader = new GLTFLoader();
    this.fbxLoader = new FBXLoader();
    this.mtlLoader = new MTLLoader();
    this.pmremGenerator.compileEquirectangularShader();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 0.8;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.camera.lookAt(this.cameraTarget.position);


    // Ground
    this.groundTextureLoaderRes();

    // Rocket
    const renderRocketPromise = new Promise((res, rej) => this.fbxLoader.load('obj/rocket/rocket.fbx',
      (object) => {
        this.fBXLoaderRes(object);
        setTimeout(() => {
          res();
        }, 5000);

      },
      () => { },
      () => rej()
    ));

    // Fire
    const renderFirePromise = new Promise((res, rej) => this.gltfLoader.load('obj/fire/scene.gltf',
      (gltf) => {
        this.gltfLoaderRes(gltf);
        res();
      },
      () => { },
      () => rej()
    ));

    // Jerrycan
    const renderFirstJerrycanPromise = new Promise((res, rej) => this.gltfLoader.load('obj/jerrycan/scene.gltf',
      (gltf) => {
        this.gltfJerrycanLoaderRes(gltf);
        res();
      },
      () => { },
      () => rej()
    ));

    const renderSecondtJerrycanPromise = new Promise((res, rej) => this.gltfLoader.load('obj/jerrycan/scene.gltf',
      (gltf) => {
        this.gltfSecondJerrycanLoaderRes(gltf);
        res();
      },
      () => { },
      () => rej()
    ));

    // Clouds
    let delta = (this.rocketYPositionsFromPageHeight(4) - this.rocketYPositionsFromPageHeight(3)) / 2;
    let xPosMap = new Map();
    const renderCloudsPromises = [];
    Array(25).fill().forEach((item, index) => {
      let cloudIterator = (index % 7) + 3;
      let positionX = -150 + (Math.floor(Math.random() * 300) + 1);
      while (xPosMap.has(positionX)) {
        positionX = -150 + (Math.floor(Math.random() * 300) + 1);
      }
      xPosMap.set(positionX);
      let positionZ = -(Math.floor(Math.random() * 50));
      renderCloudsPromises.push(
        new Promise((res, rej) => this.gltfLoader.load(`obj/clouds/cloud${cloudIterator}.gltf`,
          (gltf) => {
            gltf.scene.position.set(positionX, this.rocketYPositionsFromPageHeight(3) + delta, positionZ);
            gltf.scene.rotateY(THREE.Math.degToRad(Math.random() < 0.5 ? 0 : 180))
            gltf.scene.name = `cloud${index}`;
            this.state.scene.add(gltf.scene);
            res();
          },
          () => { },
          () => rej()
        ))
      );
    });

    // Helpers
    // this.gridHelper = new THREE.GridHelper(200, 50);
    // this.state.scene.add(this.gridHelper);

    // Background
    const renderBackgroundPromise = new Promise((res, rej) => {
      new THREE.TextureLoader().load('space.jpg',
        (spaceTexture) => {
          let tempScene = this.state.scene;
          tempScene.background = spaceTexture;
          this.setState({ scene: tempScene });
          res();
        },
        () => { },
        () => rej(),);
    });


    // Stars
    let geometries = Array(1000).fill().map(() => new THREE.SphereGeometry(0.06, 10, 10));
    geometries.forEach((geometry) => this.setStarPosition(geometry))
    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
      geometries, false);
    const material = new THREE.MeshStandardMaterial({ color: 0xffffff });
    const stars = new THREE.Mesh(mergedGeometry, material);
    stars.name = 'stars';
    stars.receiveShadow = false;
    stars.dropShadow = false;
    stars.position.set(0, 5, -2);
    this.state.scene.add(stars);

    Promise.all([renderRocketPromise, renderFirePromise, renderFirstJerrycanPromise, renderSecondtJerrycanPromise, ...renderCloudsPromises, renderBackgroundPromise]).then(() => {
      this.handleObjectLoaded();
    });

    // Scroll event listener
    document.body.onscroll = (e) => this.moveCamera(e);


  }

  render() {
    return (
      <div className={
        styles.ThreeD
      }
      />
    )
  }
}

ThreeD.propTypes = {
  pageHeight: PropTypes.number,
  mouseNormalizedPosition: PropTypes.object,
  clickHandler: PropTypes.func,
  reset3DObjectsPosition: PropTypes.bool,
  scrollIndex: PropTypes.number,
  rocketNormalizedPosition: PropTypes.func,
  onItemsLoadingComplete: PropTypes.func,
  pageWidth: PropTypes.number,
  contactBtnClick: PropTypes.bool,
};

ThreeD.defaultProps = {};

export default ThreeD;