

import React from 'react'
import styles from './FourthPage.module.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const experience = [
  {
    title: "Full stack developer",
    at: {
      text: "SundaySky",
      link: "https://www.sundaysky.com"
    },
    date: "2021 - Current",
    stack: ["React.js", "Apollo GraphQL", "Node.js", "TypeScript", "DynamoDB", "Postgress", "Jest", "Cypress", "Material-UI"],
    bullets: [
      "Spearheaded the implementation of a new responsive front-end design for the company's flagship product.",
      "Designed and developed the infrastructure of a new scalable component library.",
      "Development of end-to-end features with a commitment to seamless collaboration with both UI and Product teams.",
      "Collaboratively redesigned and refactored the product's user management system, ensuring robust scalability and uncompromising security.",
      "Implementation of unit, integration, and end-to-end tests.",

    ]
  },
  {
    title: "Full stack developer",
    at: {
      text: "Moneytor",
      link: "https://www.moneytor.co.il"
    },
    date: "2020 - 2021",
    stack: ["React.js", "Node.js", "Express.js", "TypeScript", "MongoDB", "Jest", "Bootstrap"],
    bullets: [
      "Design and develop of  HTML5, SASS, Bootstrap, and  React.js components that meets ES6 standardization.",
      "Design and develop RESTful API endpoints with the use of Node.js and Express.js routes and middlewares.",
      "Developed multilingual user interface as SPA (using React-Router), whith cross browser compatibility issues and responsiveness in mind.",
      "Use of state management of stores using MobX thus applying Functional Reactive Programming.",
      "Development of component testing for UI using Jest.",
      "Cloud computing usage of Azure Blobs, VMs, CosmosDB and App services."
    ]
  },
  {
    title: "Full stack developer",
    at: {
      text: "T predict",
      link: "https://tpredict.com/"
    },
    date: "2019 - 2020",
    stack: ["Angular", "Node.js", "Express.js", "TypeScript", "SQL", "Jest", "Bootstrap"],
    bullets: [
      "Design and develop of HTML5, CSS3, Bootstrap, and Angular (2.x) components, modules and services that meets W3C standards.",
      "Design and develop RESTful API endpoints with the use of Node.js and Express.js routes and middlewares.",
      "Developed multilingual user interface as SPA, whith cross browser compatibility issues and responsiveness in mind.",
      "Usage of Angular services providers for server communication, vlidation, etc, as programming principles.",
      "Usagee of Sockets for concurrency in the UI."
    ]
  },
  {
    title: "Freelance web developer",
    date: "2018 - 2019",
    stack: ["JS", "HTML5", "CSS3", "WP", "Joomla", "Magento"],
    bullets: [
      "Design and develop of static HTML, CSS and Bootstrap websites that meets W3C standards.",
      "Use of JavaScript and Jquery for development of UI modifications as needed.",
      "Application of optimization techniques to reduce loading time and improve SEO capabilites",
    ]
  }
]


class FourthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      pageHeight: this.props.pageHeight,
      selectedCard: 0,
      innerScroll: false
    };

    this.onScroll = (e) => {
      if (e.currentTarget.scrollY > this.props.pageHeight / 32 * 12.5) {
        this.setState({ position: (this.props.pageHeight / 32 * 12.5 - e.currentTarget.scrollY) });
      } else if (e.currentTarget.scrollY < this.props.pageHeight / 32 * 10.5) {
        this.setState({ position: (e.currentTarget.scrollY - this.props.pageHeight / 32 * 10.5) });
      } else {
        this.setState({ position: 0 });
      }
    }
  }

  componentDidMount() {
    this.setState({ pageHeight: this.props.pageHeight })
    window.addEventListener('scroll', this.onScroll);
    addEventListener("wheel", (event) => {
      this.state.innerScroll && this.setState({ selectedCard: (((this.state.selectedCard + (event.deltaY <= 0 ? -1 : 1)) % experience.length) + experience.length) % experience.length });
    }
    );

  }

  render() {
    return (
      <div className={styles.SecondPage} id="fourth-page">
        <div className={styles.NavigatorContainer} style={{
          visibility: this.state.position === 0 ? 'visible' : 'hidden',
          opacity: this.state.position === 0 ? 0.7 : 0,
          transitionDelay: this.state.position === 0 ? '2s' : '0s',
        }}
        >
          <div className={styles.Navigator}>
            <div className={styles.ForwardContainer} onClick={() => this.props.scroll('up')}>Portfolio and projects {`>`}</div>
            <div className={styles.BackwardContainer} onClick={() => this.props.scroll('down')}>{`<`} A bit about me </div>
          </div>
        </div>
        <div className={styles.EffectsContainer} style={{ right: this.state.position }}>
          <div className={styles.ContentContainer} style={{ visibility: this.state.position === 0 ? 'visible' : 'hidden', opacity: this.state.position === 0 ? 1 : 0 }} >
            <h4 style={{
              opacity: this.state.position === 0 ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              {"Work".split("").map((letter, index) => <span key={`char-${index}`} className='CharEffect'>{letter}</span>)}
              &nbsp;
              {"experience".split("").map((letter, index) => <span key={`char-${index}`} className='CharEffect'>{letter}</span>)}
              <div className={styles.LineContainer} ><div className={styles.Line} /></div>
            </h4>

            <div className={styles.CardsContainer} style={{
              opacity: this.state.position === 0 ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              <div 
                className={styles.CardSelectorContainer} 
                onMouseEnter={() => {
                  this.setState({ innerScroll: true });
                  this.props.mouseEnteredInnerScroll();
                }}
                onMouseLeave={() => {
                  this.setState({ innerScroll: false });
                  this.props.mouseLeftInnerScroll();
                }}
              >
                {
                  experience.map((item, index) => 
                    <div 
                    className={`${styles.CardButton} ${this.state.selectedCard >= index && styles.SelectedCardButton}`} 
                    onClick={() => this.setState({ selectedCard: index })}
                    key={`selector-${index}`}
                    >
                      {item.at?.text || item.title.split(" ")[0]}
                      </div>
                  )
                }
                <div className={styles.CardFooter} />
              </div>
              {experience.map((item, index) =>
                <div
                  className={styles.CurrentCardContainer}
                  style={{ opacity: this.state.selectedCard === index ? 1 : 0 }}
                  key={`experience-${index}`}
                >
                  <div className={styles.HeadingContainer}>
                    <h5>{item.title} {item.at ? <>@ <a href={item.at.link} target="_blank" rel="noreferrer">{item.at.text}</a></> : <></>}</h5>
                    <div className={styles.DateAndTagContainer}>
                      <div className={styles.DateContainer}>
                        {item.date} &nbsp;|&nbsp;
                      </div>
                      <div className={styles.TagContainer}>
                        
                        {item.stack.map((item, sIndex) => <div className={styles.Tag} key={`stack-item-${sIndex}`}>{item}</div>)}
                      </div>
                    </div>
                  </div>
                  {item.bullets.map((bullet, bIndex) =>
                    <div className={styles.BulletContainer} key={`bullent-${bIndex}`}>
                      <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" />
                      <div className='BulletParagraph'>
                        <p>
                          {bullet}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    )
  }
}

FourthPage.propTypes = {
  pageHeight: PropTypes.number,
  mouseEnteredInnerScroll: PropTypes.func,
  mouseLeftInnerScroll: PropTypes.func,
  scroll: PropTypes.func
};

FourthPage.defaultProps = {};

export default FourthPage;
