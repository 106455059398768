import './App.css';
import ThreeD from './components/ThreeD/ThreeD';
import React from 'react';
// import FirstPage from './components/FirstPage/FirstPage';
import SecondPage from './components/SecondPage/SecondPage';
import ThirdPage from './components/ThirdPage/ThirdPage';
import FourthPage from './components/FourthPage/FourthPage';
import FifthPage from './components/FifthPage/FifthPage';
import Loading from './components/Loading/Loading';
import SocialIcons from './components/SocialIcons/SocialIcons.lazy';
import SixthPage from './components/SixthPage/SixthPage';
import ShootingStar from './components/ShootingStar/ShootingStar';
import SpeechBubble from './components/SpeechBubble/SpeechBubble.lazy';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageHeight: 0,
      pageWidth: 0,
      loading: true,
      scrollIndex: 1,
      scrollLock: false,
      innerScroll: false,
      mouseNormalizedPosition: {
        x: 0,
        y: 0
      },
      showSpeechBubble: false,
      rocketVector: {
        x: 0,
        y: 0,
        z: 0
      },
      clickMePos: undefined,
      contactBtnClick: false,
      reset3DObjectsPosition: false,
      stopAnimations: false,
      delayFormAnimation: true
    };
    this.handleContactBtnClick = this.handleContactBtnClick.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
    this.handleScrollEvent = this.handleScrollEvent.bind(this);
    this.handleMouseClickEvent = this.handleMouseClickEvent.bind(this);
    this.handleMouseClickOn3DObjEvent = this.handleMouseClickOn3DObjEvent.bind(this);
    this.handleMouseEnteredInnerScroll = this.handleMouseEnteredInnerScroll.bind(this);
    this.handleMouseLeftInnerScroll = this.handleMouseLeftInnerScroll.bind(this);
    this.handleRocketNormalizedPosition = this.handleRocketNormalizedPosition.bind(this);
    this.handleItemsLoadingComplete = this.handleItemsLoadingComplete.bind(this);
    this.scroll = this.scroll.bind(this);

    this.pageRefs = {
      footerRef: React.createRef(),
      secondPageRef: React.createRef(),
      thirdPageRef: React.createRef(),
      fourthPageRef: React.createRef(),
      fifthPageRef: React.createRef(),
      sixthPageRef: React.createRef()
    }
    this.pageHeight = 0;
  }

  componentDidMount() {
    this.setState({ pageHeight: document.documentElement.scrollHeight, pageWidth: window.innerWidth, scrollPosition: Number(this.pageRefs.footerRef.current.offsetTop - (document.documentElement.clientHeight)), reset3DObjectsPosition: true });
    this.pageHeight = document.documentElement.scrollHeight;
    document.addEventListener("keydown", this.handleKeyPressed, false);
    document.addEventListener("mousewheel", this.handleScrollEvent, { passive: false });
    window.addEventListener('resize', this.handleWindowResize);
    if (this.pageRefs !== undefined && this.pageRefs.footerRef !== undefined && this.pageRefs.footerRef.current) {
      window.scrollTo(0, this.pageRefs.footerRef.current.offsetTop - (document.documentElement.clientHeight / 2));
    }
  }

  scroll(direction) {
    let currentScrollIndex = this.state.scrollIndex;
    const pageRefsArr = Object.keys(this.pageRefs).map((k) => this.pageRefs[k]);
    if (!this.state.scrollLock && pageRefsArr[currentScrollIndex]) {
      this.setState({ scrollLock: true });
      if (direction === 'up' && currentScrollIndex < 6 && pageRefsArr[currentScrollIndex + 1] && pageRefsArr[currentScrollIndex + 1].current) {
        currentScrollIndex = currentScrollIndex + 1;
        this.setState({ scrollIndex: currentScrollIndex });
        this.scrollToElem(pageRefsArr[currentScrollIndex].current.offsetTop, direction, window.innerHeight);
      } else if (direction === 'down' && currentScrollIndex === 5) {
        this.handleJumpToFifthPage();
      } else if (direction === 'down' && currentScrollIndex > 0 && pageRefsArr[currentScrollIndex - 1] && pageRefsArr[currentScrollIndex - 1].current) {
        currentScrollIndex = currentScrollIndex - 1;
        this.setState({ scrollIndex: currentScrollIndex });
        this.scrollToElem(pageRefsArr[currentScrollIndex].current.offsetTop, direction, window.innerHeight);
      } else {
        this.setState({ scrollLock: false });
      }
    }
  }

  scrollToElem(elementOffsetTop, direction, clientHeight) {
    if (this.state.stopAnimations) {
      this.setState({ stopAnimations: false });
      return;
    }
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > elementOffsetTop && direction === 'up') {
      requestAnimationFrame(() => { 
        window.scrollTo(elementOffsetTop, c > 70 ? c - 70 : c > 15 ? c - 15 : c - c);
        this.scrollToElem(elementOffsetTop, direction, clientHeight) 
      });
      
    } else if (c < elementOffsetTop && direction === 'down') {
      if (this.state.scrollIndex === 0) {
        if (c + clientHeight >= elementOffsetTop && direction === 'down') {
          this.setState({ scrollLock: false });
          return;
        }
      }
      requestAnimationFrame(() => {
        window.scrollTo(elementOffsetTop, c > 70 ? c + 70 : c > 15 ? c + 15 : c + c);
        this.scrollToElem(elementOffsetTop, direction, clientHeight)
      });
    } else {
      this.setState({ scrollLock: false });
      return;
    }
  }

  handleWindowResize() {
    if (this.pageRefs !== undefined && this.pageRefs.footerRef !== undefined && this.pageRefs.footerRef.current) {
      this.handleResetApp();
      setTimeout(() => {
        this.setState({ pageHeight: document.documentElement.scrollHeight, pageWidth: window.innerWidth, scrollPosition: Number(this.pageRefs.footerRef.current.offsetTop - (document.documentElement.clientHeight)) });
      }, 100);
    }
  }

  handleScrollEvent(event) {
    if (!this.state.innerScroll && event.wheelDeltaY > 0) {
      this.scroll('up');
    } else if (!this.state.innerScroll &&event.wheelDeltaY < 0) {
      this.scroll('down');
    }
  }

  handleKeyPressed(event) {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.scroll('up');
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.scroll('down');
    }
  }

  handleMouseClickEvent(event) {
    this.setState({
      mouseNormalizedPosition: {
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1
      }
    });
  }

  handleResetApp() {
    this.setState({
      loading: true,
      delayFormAnimation: true,
      reset3DObjectsPosition: true
    });
    setTimeout(() => {
      window.scrollTo(0, this.pageRefs.secondPageRef.current.offsetTop);
      this.setState({
        stopAnimations: true
      });
      setTimeout(() => {
        this.setState({
          scrollIndex: 1,
          reset3DObjectsPosition: true,
          stopAnimations: false
        });
        setTimeout(() => {
          this.setState({
            reset3DObjectsPosition: false,
            scrollLock: false,
            loading: false
          });
        }, 100);
      }, 200);
    }, 500);
  }

  handleJumpToFifthPage() {
    if (this.pageRefs.fifthPageRef?.current) {
      this.setState({
        loading: true,
        delayFormAnimation: true
      });
      setTimeout(() => {
        window.scrollTo(0, this.pageRefs.fifthPageRef.current.offsetTop);
        this.setState({
          stopAnimations: true
        });
        setTimeout(() => {
          this.setState({
            scrollIndex: 4,
            reset3DObjectsPosition: true,
            stopAnimations: false
          });
          setTimeout(() => {
            this.setState({
              reset3DObjectsPosition: false,
              scrollLock: false,
              loading: false
            });
          }, 100);
        }, 200);
      }, 500);
    }
  }


  handleJumpToSixthPage() {
    if (this.pageRefs.fifthPageRef) {
      this.setState({
        loading: true,
        delayFormAnimation: false
      });
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.setState({
          stopAnimations: true,
          delayFormAnimation: false
        });
        setTimeout(() => {
          this.setState({
            scrollIndex: 5,
            reset3DObjectsPosition: true,
            stopAnimations: false,
            delayFormAnimation: false
          });
          setTimeout(() => {
            this.setState({
              reset3DObjectsPosition: false,
              scrollLock: false,
              loading: false,
              delayFormAnimation: false
            });
          }, 100);
        }, 200);
      }, 500);
    }
  }

  handleMouseClickOn3DObjEvent(vector) {
    this.setState({ showSpeechBubble: true, rocketVector: vector });

    setTimeout(() => {
      this.setState({ showSpeechBubble: false });
    }, 3000);
  }

  handleMouseEnteredInnerScroll() {
    this.setState({ scrollLock: true, innerScroll: true });
  }

  handleMouseLeftInnerScroll() {
    this.setState({ scrollLock: false, innerScroll: false });
  }

  handleRocketNormalizedPosition(vector) {
    this.setState({ clickMePos: vector });
  }


  handleContactBtnClick() {
    this.handleJumpToSixthPage();
  }

  handleItemsLoadingComplete() {
    setTimeout(() => {
      this.setState({ loading: false, reset3DObjectsPosition: false });
    }, 100);
  }

  render() {
    return (
      <>
        <div className="app" id="app" onClick={this.handleMouseClickEvent}>
          <Loading loading={this.state.loading} />
          <SpeechBubble pageHeight={this.state.pageHeight} showSpeechBubble={this.state.showSpeechBubble} scrollIndex={this.state.scrollIndex} rocketVector={this.state.rocketVector} />
          <ShootingStar pageHeight={this.state.pageHeight} />
          <ThreeD
            pageHeight={this.state.pageHeight}
            pageWidth={this.state.pageWidth}
            mouseNormalizedPosition={this.state.mouseNormalizedPosition}
            rocketNormalizedPosition={this.handleRocketNormalizedPosition}
            clickHandler={this.handleMouseClickOn3DObjEvent}
            reset3DObjectsPosition={this.state.reset3DObjectsPosition}
            scrollIndex={this.state.scrollIndex}
            contactBtnClick={this.state.contactBtnClick}
            onItemsLoadingComplete={this.handleItemsLoadingComplete}
          />
          <div id="sixthPage" ref={this.pageRefs.sixthPageRef}>
            {this.state.loading ? <></> : <SixthPage pageHeight={this.state.pageHeight} scroll={this.scroll} delayAnimation={this.state.delayFormAnimation} />}
          </div>
          <div className="gap" />
          <div className="gap" />
          <div id="fifthPage" ref={this.pageRefs.fifthPageRef} >
            <FifthPage
              pageHeight={this.state.pageHeight}
              mouseEnteredInnerScroll={this.handleMouseEnteredInnerScroll}
              mouseLeftInnerScroll={this.handleMouseLeftInnerScroll}
              scroll={this.scroll}
            />
          </div>
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div id="fourthPage" ref={this.pageRefs.fourthPageRef} >
            <FourthPage
              pageHeight={this.state.pageHeight}
              mouseEnteredInnerScroll={this.handleMouseEnteredInnerScroll}
              mouseLeftInnerScroll={this.handleMouseLeftInnerScroll}
              scroll={this.scroll}
            />
          </div>
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div id="thirdPage" ref={this.pageRefs.thirdPageRef} >
            <ThirdPage pageHeight={this.state.pageHeight} scroll={this.scroll} />
          </div>
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div className="gap" />
          <div id="secondPage" ref={this.pageRefs.secondPageRef}>
            <SecondPage pageHeight={this.pageHeight} scroll={this.scroll} clickMePos={this.state.clickMePos} onContactBtnClick={this.handleContactBtnClick} />
          </div>
          <div id="footer" ref={this.pageRefs.footerRef} />
          <SocialIcons />
        </div>
        <div className="Support">
          <h1>Oops..</h1>
          <p>It seems like you are trying to view this site from a not supported device. This site is currently supporting only resolutions that are <b>&gt; 1024</b></p>
          <a href="mailto:johnyzelba@gmail.com"><b>Contact me</b></a>
        </div>
      </>
    );
  }
}

export default App;
