import React from 'react'
import styles from './SecondPage.module.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretLeft, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Arrow from './media/twirl-arrow.png'
class SecondPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      pageHeight: this.props.pageHeight,
      clickMePos: this.props.clickMePos
    };

    this.onScroll = (e) => {
      if (e.currentTarget.scrollY > this.props.pageHeight / 32 * 31) {
        this.setState({ position: (this.props.pageHeight / 32 * 31 - e.currentTarget.scrollY) });
      } else if (e.currentTarget.scrollY < this.props.pageHeight / 32 * 29) {
        this.setState({ position: (e.currentTarget.scrollY - this.props.pageHeight / 32 * 29) });
      } else {
        this.setState({ position: 0 });
      }
    }
  }

  componentDidMount() {
    this.setState({ pageHeight: this.props.pageHeight, clickMePos: this.props.clickMePos })
    window.addEventListener('scroll', this.onScroll);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.clickMePos !== this.state.clickMePos) {
      this.setState({ clickMePos: this.props.clickMePos })
    }
    if (nextProps.pageHeight !== this.state.pageHeight) {
      this.setState({ pageHeight: nextProps.pageHeight })
    }
    return true;
  }

  render() {
    return (
      <div className={styles.SecondPage} id="second-page">
        <div className={styles.ClickMeSignContainer} style={{ left: this.state.clickMePos?.x || 0, top:this.state.clickMePos?.y || 0}}>
          <img src={Arrow}/>
          <div className={styles.RotatedTextContainer}>Click Me!</div>
        </div>

        <div className={styles.UnderRocketContainer} style={{ left: this.state.clickMePos?.x || 0, top:this.state.clickMePos?.y || 0}}>
          <div className={styles.UnderRocketQuoteContainer}>The ships hung in the sky in much the same way that bricks don&apos;t.</div>
          <div className={styles.UnderRocketQuoteContainerQuote}>― Douglas Adams, The Hitchhiker&apos;s Guide to the Galaxy</div>
          {/* <div className={styles.UnderRocketTextContainer}>Use the keyboard <b>&apos;UP&apos;</b> button, or your mouse <b>scrolling wheel</b></div> */}
          <div className={styles.RowContainer}>
            <div className={styles.keyboardArrowsContainer}>
              <div className={styles.keyboardArrowsUpContainer}>
                <div className={styles.keyboardArrow} onClick={() => this.props.scroll('up')}><FontAwesomeIcon icon={faCaretUp} /></div>
              </div>
              <div className={styles.keyboardArrowsRestContainer}>
                <div className={styles.keyboardArrow}><FontAwesomeIcon icon={faCaretLeft} /></div>
                <div className={styles.keyboardArrow}><FontAwesomeIcon icon={faCaretDown} /></div>
                <div className={styles.keyboardArrow}><FontAwesomeIcon icon={faCaretRight} /></div>
              </div>
            </div>
            <div className={styles.MouseWheelContainer}>
              <div className={styles.WheelContainer}>
                <div className={styles.Wheel}><div className={styles.Dot}></div></div>
              </div>
              <div className={styles.MouseContainer}>
                <div className={styles.MouseLeftKey}></div>
                <div className={styles.MouseRightKey}></div>
              </div>
              <div className={styles.MouseBack}>
              </div>
            </div>
          </div>
          <div className={styles.TextContainer}>
            <span>Press the <b>&apos;UP&apos;</b> arrow key, or use the <b>mouse wheel</b>.</span>
          </div>
        </div>
        <div className={styles.EffectsContainer} style={{ right: this.state.position }}>
          <div className={styles.ContentContainer} style={{ 
            visibility: this.state.position === 0 ? 'visible' : 'hidden', 
            opacity: this.state.position === 0 ? 1 : 0 
            }}
            >
            <div className={styles.HeadLineTextContainer}>
              <div className={styles.HeadLineTextContainerBackGround} />
              <div className={styles.HeadLineTextContainerBackGround} />
              <div className={styles.HeadLineTextContainerBackGround} />
              <div className={styles.HeadLineTextContainerBackGround} />
              <div className={styles.HeadLineTextContainerBackGround} />
              <div className={styles.HeadLineTextContainerBackGround} />
              <div className={styles.HeadLineTextContainerBackGround} />
              <div className={styles.OverFlowContainer}>
                <div className={styles.HiContainer} style={{
                  opacity: this.state.position === 0 ? 1 : 0
                }}
                >,</div>
              </div>
              <h2 style={{
                opacity: this.state.position === 0 ? 1 : 0,
                transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
              }}
              >
                {"My".split("").map((letter, index) => <span key={`char-${index}`} className='CharEffect'>{letter}</span>)}
                &nbsp;
                {"name".split("").map((letter, index) => <span key={`char-${index}`} className='CharEffect'>{letter}</span>)}
                &nbsp;
                {"is".split("").map((letter, index) => <span key={`char-${index}`} className='CharEffect'>{letter}</span>)}
                &nbsp;
                {"Johnny".split("").map((letter, index) => <span key={`char-${index}`} className='CharEffect'>{letter}</span>)}
              </h2>
              <div className={styles.h3Container}>
                <h3 style={{
                  transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -200%, 0)'
                }}>
                  I&apos;m a full-stack web developer
                </h3>
              </div>
              <p style={{
                opacity: this.state.position === 0 ? 1 : 0,
              }}>
I specialize in developing user friendly interfaces, while maintaining a clean, and efficient code. I am passionate about web development with a perpetual curiosity for exploring new capabilities.
              </p>
              <div className={styles.ContactButtonContainer} style={{
                opacity: this.state.position === 0 ? 1 : 0,
                transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
              }}>

                <div className='contactButton'>
                  <div className={styles.ContactButton} onClick={this.props.onContactBtnClick}>
                    <FontAwesomeIcon className={styles.Icon} icon={faCaretRight} size="lg" />
                    &nbsp;
                    Contact Me
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SecondPage.propTypes = {
  pageHeight: PropTypes.number,
  clickMePos: PropTypes.object,
  onContactBtnClick: PropTypes.func,
  scroll: PropTypes.func
};

SecondPage.defaultProps = {};

export default SecondPage;
