import React from 'react'
import styles from './ThirdPage.module.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCss3Alt,
  faReact,
  faNode,
  faHtml5,
  faAngular,
  faBootstrap,
  faSass,
  faLess,
  faGithub,
  faNpm,
  faStackOverflow,
  faUbuntu,
  faYarn,
  faJira,
  faAws,
  faDocker,
  faFigma,
  faHotjar,
  faJs,
} from '@fortawesome/free-brands-svg-icons';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import Img from './media/profile.jpg'


class ThirdPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      pageHeight: this.props.pageHeight
    };

    this.onScroll = (e) => {
      if (e.currentTarget.scrollY > this.props.pageHeight / 32 * 21) {
        this.setState({ position: (this.props.pageHeight / 32 * 21 - e.currentTarget.scrollY) });
      } else if (e.currentTarget.scrollY < this.props.pageHeight / 32 * 19) {
        this.setState({ position: (e.currentTarget.scrollY - this.props.pageHeight / 32 * 19) });
      } else {
        this.setState({ position: 0 });
      }
    }
  }

  componentDidMount() {
    this.setState({ pageHeight: this.props.pageHeight })
    window.addEventListener('scroll', this.onScroll);
  }

  render() {
    return (
      <div className={styles.ThirdPage} id="third-page">
        <div className={styles.NavigatorContainer} style={{
          visibility: this.state.position === 0 ? 'visible' : 'hidden',
          opacity: this.state.position === 0 ? 0.7 : 0,
          transitionDelay: this.state.position === 0 ? '1.5s' : '0s',
        }}
        >
          <div className={styles.Navigator}>
            <div className={styles.ForwardContainer} onClick={() => this.props.scroll('up')}>Check my experience {`>`}</div>
            <div className={styles.BackwardContainer} onClick={() => this.props.scroll('down')}>{`<`} Back to Home page </div>
          </div>
        </div>
        <div className={styles.EffectsContainer} style={{ left: this.state.position }}>
          <div className={styles.transformContainer}>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faGithub} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAws} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faGithub} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAws} size="10x" />
                </div>

                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
              </div>
            </div>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faReact} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNode} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faHtml5} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAngular} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faReact} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNode} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faHtml5} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAngular} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faReact} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNode} size="10x" />
                </div>
              </div>
            </div>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faBootstrap} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faSass} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faLess} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faJira} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNpm} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faBootstrap} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faSass} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faLess} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faJira} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNpm} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faBootstrap} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faSass} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faLess} size="10x" />
                </div>
              </div>
            </div>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faGithub} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAws} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faGithub} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAws} size="10x" />
                </div>

                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
              </div>
            </div>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faDocker} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faFigma} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faHotjar} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faJs} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faDocker} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faFigma} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faHotjar} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faJs} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faDocker} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faFigma} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faHotjar} size="10x" />
                </div>
              </div>
            </div>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faBootstrap} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faSass} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faLess} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faJira} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNpm} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faBootstrap} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faSass} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faLess} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faJira} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNpm} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faBootstrap} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faSass} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faLess} size="10x" />
                </div>
              </div>
            </div>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faGithub} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAws} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faGithub} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAws} size="10x" />
                </div>

                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faStackOverflow} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faUbuntu} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faYarn} size="10x" />
                </div>
              </div>
            </div>
            <div className={styles.buzzWordsContainer}>
              <div className={styles.Slider}>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faReact} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNode} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faHtml5} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAngular} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faReact} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNode} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faHtml5} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faAngular} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faCss3Alt} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faReact} size="10x" />
                </div>
                <div className={styles.FloatingIcon}>
                  <FontAwesomeIcon icon={faNode} size="10x" />
                </div>
              </div>
            </div>
          </div>



          <div className={styles.ContentContainer} style={{ 
            visibility: this.state.position === 0 ? 'visible' : 'hidden', 
            opacity: this.state.position === 0 ? 1 : 0 
            }}
            >
            <h4 style={{
              opacity: this.state.position === 0 ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              <span className='CharEffect'>A</span>
              <span className='CharEffect'>b</span>
              <span className='CharEffect'>o</span>
              <span className='CharEffect'>u</span>
              <span className='CharEffect'>t</span>
              &nbsp;
              <span className='CharEffect'>m</span>
              <span className='CharEffect'>e</span>
              <div className={styles.LineContainer} ><div className={styles.Line} /></div>
            </h4>
            <p className={styles.ContentPar} style={{
              opacity: this.state.position === 0 ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              While pursuing my Bachelor&apos;s degree, I freelanced as a web designer and developer for small to medium-sized businesses. Over the past four years, I&apos;ve been immersed in the world of web application programming, primarily focusing on JavaScript frameworks such as Angular, React.js, and Node.js.
            </p>
            <p className={styles.ContentPar} style={{
              opacity: this.state.position === 0 ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              In my roles as a Full-stack web developer, I&apos;ve gained extensive experience in developing single-page applications that are robust, responsive and are cross browser compatible. I&apos;m well-versed in utilizing various NPM packages, and enjoy exploring new frameworks.</p>
            <p className={styles.ContentPar} style={{
              opacity: this.state.position === 0 ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              My ultimate goal is to create sophisticated, user-friendly applications that are both intuitive and easy to navigate. I believe that my creativity, attention to detail, and meticulous approach will be valuable assets in achieving this objective.
            </p>
            <h5 style={{
              opacity: this.state.position === 0 ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              <span className='CharEffect'>S</span>
              <span className='CharEffect'>k</span>
              <span className='CharEffect'>i</span>
              <span className='CharEffect'>l</span>
              <span className='CharEffect'>l</span>
              <span className='CharEffect'>s</span>
              <div className={styles.LineContainer} ><div className={styles.Line} /></div>
            </h5>
            <div className={styles.SkillsContainer}>
            <img src={Img} className={styles.profileImage}/>
              <div className={styles.ListContainer}>
                <div className={styles.ItemContainer}>
                  <span>
                    {/* <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" className="icon" /> */}
                    HTML
                  </span>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBar}>
                      <span className={styles.Bar}>
                        <span className={styles.Progress} style={{ width: '79%', animation: this.state.position == 0 ? 'progress79 2.5s ease-in-out forwards' : 'none' }}></span>
                      </span>
                    </div>
                    <div className={styles.TextContainer}>
                      <FontAwesomeIcon className={styles.Icon} icon={faCaretUp} size="xs" color="#FA5849" style={{ left: 'calc(79% + 5.5px)' }} />
                      <p style={{ left: '79%' }}>79<span style={{ fontSize: '9px' }}>%</span></p>
                    </div>
                  </div>
                </div>
                <div className={styles.ItemContainer}>
                  <span>
                    {/* <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" className="icon" /> */}
                    CSS
                  </span>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBar}>
                      <span className={styles.Bar}>
                        <span className={styles.Progress} style={{ width: '83%', animation: this.state.position == 0 ? 'progress83 2.5s ease-in-out forwards' : 'none' }}></span>
                      </span>
                    </div>
                    <div className={styles.TextContainer}>
                      <FontAwesomeIcon className={styles.Icon} icon={faCaretUp} size="xs" color="#FA5849" style={{ left: 'calc(83% + 5.5px)' }} />
                      <p style={{ left: '83%' }}>83<span style={{ fontSize: '9px' }}>%</span></p>
                    </div>
                  </div>
                </div>
                <div className={styles.ItemContainer}>
                  <span>
                    {/* <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" className="icon" /> */}
                    React
                  </span>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBar}>
                      <span className={styles.Bar}>
                        <span className={styles.Progress} style={{ width: '58%', animation: this.state.position == 0 ? 'progress58 2.5s ease-in-out forwards' : 'none' }}></span>
                      </span>
                    </div>
                    <div className={styles.TextContainer}>
                      <FontAwesomeIcon className={styles.Icon} icon={faCaretUp} size="xs" color="#FA5849" style={{ left: 'calc(58% + 5.5px)' }} />
                      <p style={{ left: '58%' }}>58<span style={{ fontSize: '9px' }}>%</span></p>
                    </div>
                  </div>
                </div>
                <div className={styles.ItemContainer}>
                  <span>
                    {/* <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" className="icon" /> */}
                    Node.js
                  </span>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBar}>
                      <span className={styles.Bar}>
                        <span className={styles.Progress} style={{ width: '50%', animation: this.state.position == 0 ? 'progress50 2.5s ease-in-out forwards' : 'none' }}></span>
                      </span>
                    </div>
                    <div className={styles.TextContainer}>
                      <FontAwesomeIcon className={styles.Icon} icon={faCaretUp} size="xs" color="#FA5849" style={{ left: 'calc(50% + 5.5px)' }} />
                      <p style={{ left: '50%' }}>50<span style={{ fontSize: '9px' }}>%</span></p>
                    </div>
                  </div>
                </div>
                <div className={styles.ItemContainer}>
                  <span>
                    {/* <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" className="icon" /> */}
                    Angular
                  </span>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBar}>
                      <span className={styles.Bar}>
                        <span className={styles.Progress} style={{ width: '48%', animation: this.state.position == 0 ? 'progress48 2.5s ease-in-out forwards' : 'none' }}></span>
                      </span>
                    </div>
                    <div className={styles.TextContainer}>
                      <FontAwesomeIcon className={styles.Icon} icon={faCaretUp} size="xs" color="#FA5849" style={{ left: 'calc(48% + 5.5px)' }} />
                      <p style={{ left: '48%' }}>48<span style={{ fontSize: '9px' }}>%</span></p>
                    </div>
                  </div>

                </div>
                <div className={styles.ItemContainer}>
                  <span>
                    {/* <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" className="icon" /> */}
                    SQL
                  </span>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBar}>
                      <span className={styles.Bar}>
                        <span className={styles.Progress} style={{ width: '42%', animation: this.state.position == 0 ? 'progress42 2.5s ease-in-out forwards' : 'none' }}></span>
                      </span>
                    </div>
                    <div className={styles.TextContainer}>
                      <FontAwesomeIcon className={styles.Icon} icon={faCaretUp} size="xs" color="#FA5849" style={{ left: 'calc(42% + 5.5px)' }} />
                      <p style={{ left: '42%' }}>42<span style={{ fontSize: '9px' }}>%</span></p>
                    </div>
                  </div>
                </div>
                <div className={styles.ItemContainer}>
                  <span>
                    {/* <FontAwesomeIcon icon={faCaretRight} size="lg" color="#FA5849" className="icon" /> */}
                    Mongo
                  </span>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBar}>
                      <span className={styles.Bar}>
                        <span className={styles.Progress} style={{ width: '56%', animation: this.state.position == 0 ? 'progress56 2.5s ease-in-out forwards' : 'none' }}></span>
                      </span>
                    </div>
                    <div className={styles.TextContainer}>
                      <FontAwesomeIcon className={styles.Icon} icon={faCaretUp} size="xs" color="#FA5849" style={{ left: 'calc(56% + 5.5px)' }} />
                      <p style={{ left: '56%' }}>56<span style={{ fontSize: '9px' }}>%</span></p>
                    </div>
                  </div>
                </div>
                <div className={styles.ProgressBarTickersContainer}>
                  <div className={styles.ProgressBarTicker} />
                  <div className={styles.ProgressBarTicker} />
                  <div className={styles.ProgressBarTicker} />
                  <div className={styles.ProgressBarTicker} />
                </div>
              </div>
            </div>
            {/* <div className={styles.FloatingIconsContainer}>
              <div className={styles.FloatingIcon}>
                <FontAwesomeIcon icon={faCss3Alt} size="4x" />
              </div>
              <div className={styles.FloatingIcon}>
                <FontAwesomeIcon icon={faReact} size="4x" />
              </div>
              <div className={styles.FloatingIcon}>
                <FontAwesomeIcon icon={faNode} size="4x" />
              </div>
              <div className={styles.FloatingIcon}>
                <FontAwesomeIcon icon={faHtml5} size="4x" />
              </div>
              <div className={styles.FloatingIcon}>
                <FontAwesomeIcon icon={faAngular} size="4x" />
              </div>
              <div className={styles.FloatingIcon}>
                <FontAwesomeIcon icon={faBootstrap} size="4x" />
              </div>
              <div className={styles.FloatingIcon}>
                <FontAwesomeIcon icon={faSass} size="4x" />
              </div>
            </div> */}

          </div>
        </div>
      </div>
    )
  }
}

ThirdPage.propTypes = {
  pageHeight: PropTypes.number,
  scroll: PropTypes.func
};

ThirdPage.defaultProps = {};

export default ThirdPage;