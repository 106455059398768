import React, { lazy, Suspense } from 'react';

const LazySocialIcons = lazy(() => import('./SocialIcons'));

const SocialIcons = props => (
  <Suspense fallback={null}>
    <LazySocialIcons {...props} />
  </Suspense>
);

export default SocialIcons;
