// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_Loading__2QGVy {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: #fff;\n    transition:visibility 0.3s linear,opacity 0.3s linear;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.Loading_LoadingIcon__17fgG {\n    animation: Loading_rotate__1yl-s 1.5s linear infinite; \n}\n\n@keyframes Loading_rotate__1yl-s{\n    from {transform: rotate(0deg); \n        transform-origin: 50px 50px; }\n    to{ \n        transform: rotate(360deg); \n        transform-origin: 50px 50px;\n    }\n  }", "",{"version":3,"sources":["webpack://src/components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,MAAM;IACN,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,qDAAqD;IACrD,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,qDAAsC;AAC1C;;AAEA;IACI,MAAM,uBAAuB;QACzB,2BAA2B,EAAE;IACjC;QACI,yBAAyB;QACzB,2BAA2B;IAC/B;EACF","sourcesContent":[".Loading {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: #fff;\n    transition:visibility 0.3s linear,opacity 0.3s linear;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.LoadingIcon {\n    animation: rotate 1.5s linear infinite; \n}\n\n@keyframes rotate{\n    from {transform: rotate(0deg); \n        transform-origin: 50px 50px; }\n    to{ \n        transform: rotate(360deg); \n        transform-origin: 50px 50px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loading": "Loading_Loading__2QGVy",
	"LoadingIcon": "Loading_LoadingIcon__17fgG",
	"rotate": "Loading_rotate__1yl-s"
};
export default ___CSS_LOADER_EXPORT___;
