import React from 'react'
import styles from './SixthPage.module.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import emailjs from "emailjs-com";
class SixthPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      pageHeight: this.props.pageHeight,
      emailBtnClicked: false,
      emailSent: false,
      nameValidationFlag: false,
      emailValidationFlag: false,
      contentValidationFlag: false,
      showAttributionsContainer: false,
    };
    this.sendEmail = this.sendEmail.bind(this);
    this.FirstVideoRef = React.createRef();
    this.onScroll = (e) => {
      if (e.currentTarget.scrollY <= 0) {
        this.setState({ position: 1 });
      } else {
        this.setState({ position: 0, showAttributionsContainer: false });
      }
    }
  }

  componentDidMount() {
    this.setState({ pageHeight: this.props.pageHeight, position: !this.props.delayAnimation ? 1 : 0 })
    window.addEventListener('scroll', this.onScroll);
  }

  validateForm(e) {
    let tempValidator = {
      nameValidationFlag: false,
      emailValidationFlag: false,
      contentValidationFlag: false,
    }
    if (e.target[0].value === '') {
      tempValidator.nameValidationFlag = true;
    }
    if (e.target[1].value === '' || !/.+@.+\.[A-Za-z]+$/.test(e.target[1].value)) {
      tempValidator.emailValidationFlag = true;
    }
    if (e.target[2].value === '') {
      tempValidator.contentValidationFlag = true;
    }

    this.setState({
      nameValidationFlag: tempValidator.nameValidationFlag,
      emailValidationFlag: tempValidator.emailValidationFlag,
      contentValidationFlag: tempValidator.contentValidationFlag,
    });

    return !tempValidator.nameValidationFlag && !tempValidator.emailValidationFlag && !tempValidator.contentValidationFlag;
  }

  sendEmail(e) {
    e.preventDefault();
    this.setState({ emailBtnClicked: true })
    setTimeout(() => {
      let validation = this.validateForm(e);
      if (validation) {
        emailjs.sendForm('service_3akp7l2', 'template_lr6xccr', e.target, 'user_IMOTLJCqXGIiouTZ5URIR')
          .then(() => {
            e.target.reset();
            this.setState({ emailSent: true });
          }, () => {
            this.setState({ emailBtnClicked: false, emailSent: false });
          });
      } else {
        this.setState({ emailBtnClicked: false, emailSent: false });
      }
    }, 100);
  }

  render() {
    return (
      <div className={styles.ThirdPage} id="sixth-page" key={this.props.delayAnimation}>
        <div className={styles.NavigatorContainer} style={{
          opacity: this.state.position,
          transitionDelay: this.props.delayAnimation ? '3.5s' : '0s',
        }}
        >
          <div className={styles.BackwardContainer} onClick={() => this.props.scroll('down')}>{`<`} Portfolio and projects</div>
          <div className={styles.SeperatorContainer}>&nbsp;|&nbsp;</div>
          <div className={styles.BackwardContainer} onClick={() => this.setState({ showAttributionsContainer: !this.state.showAttributionsContainer })}>Attributions</div>
        </div>

        <div className={styles.AttributionsContainer} style={{
          opacity: this.state.position === 1 && this.state.showAttributionsContainer ? 1 : 0,
          transitionDelay: '0s',
        }}
        >
          <p><a href="https://skfb.ly/6XCGB" target="_blank" rel="noreferrer">&quot;Jerry Can&quot;</a> by Trueno is licensed under <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer">Creative Commons Attribution</a>.</p>
          <p><a href="https://skfb.ly/69UEZ" target="_blank" rel="noreferrer">&quot;Fire&quot;</a> by Guillermo T is licensed under <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer">Creative Commons Attribution</a>.</p>
          <p><a href="https://fontawesome.com/license" target="_blank" rel="noreferrer">Font awesomw Creative Commons Attribution</a></p>
        </div>

        <div className={styles.EffectsContainer} style={{ opacity: this.state.position === 1 ? 1 : 0 }}>
          <div className={styles.ContentContainer} style={{ opacity: this.state.position === 1 && !this.state.emailSent ? 1 : 0 }}>
            <h4 style={{
              opacity: this.state.position === 1 ? 1 : 0,
              transform: this.state.position === 1 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
            }}>
              <span className='CharEffect'>L</span>
              <span className='CharEffect'>e</span>
              <span className='CharEffect'>t</span>
              <span className='CharEffect'>&apos;</span>
              <span className='CharEffect'>s</span>
              &nbsp;
              <span className='CharEffect'>T</span>
              <span className='CharEffect'>a</span>
              <span className='CharEffect'>l</span>
              <span className='CharEffect'>k</span>
              <div className={styles.LineContainer} ><div className={styles.Line} /></div>
            </h4>
            <h5>What&apos;s on your mind? Feel free to contact me...</h5>
            <form onSubmit={this.sendEmail}>
              <div className={styles.RowContainer}>
                <div className={`${styles.InputContainer} ${this.state.nameValidationFlag ? styles.FlagedInput : ''}`}>
                  <input type="text" name="name" placeholder="Name" />
                  <label>
                    <div className={styles.InnerLabel}>Name</div>
                  </label>
                </div>
                <div className={`${styles.InputContainer} ${this.state.emailValidationFlag ? styles.FlagedInput : ''}`}>
                  <input type="text" name="email" placeholder="Email" />
                  <label>
                    <div className={styles.InnerLabel}>Email</div>
                  </label>
                </div>
              </div>
              <div className={styles.RowContainer}>
                <div className={`${styles.TextAreaContainer} ${this.state.contentValidationFlag ? styles.FlagedInput : ''}`}>
                  <textarea type="textarea" name="content" rows="4" placeholder="Content" />
                  <label>
                    <div className={styles.InnerLabel}>Content</div>
                  </label>
                </div>
              </div>
              <div className={styles.ContactButtonContainer} style={{
                opacity: this.state.position === 1 ? 1 : 0,
                transform: this.state.position === 1 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)'
              }}>
                <div className='contactButton'>
                  <button className={styles.ContactButton} type="submit" disabled={this.state.emailBtnClicked || this.state.emailSent}>
                    {this.state.emailBtnClicked ? <FontAwesomeIcon className={styles.SpinnerIcon} icon={faSpinner} size="lg" />
                      :
                      <>
                        <FontAwesomeIcon className={styles.Icon} icon={faCaretRight} size="lg" />
                        &nbsp;
                        Send
                      </>}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className={styles.SuccessContainer} style={{ opacity: this.state.emailSent ? 1 : 0 }}>
            Thanks for contacting me! 😄 Your message has been sent successfully.
          </div>
        </div>
      </div>
    )
  }
}

SixthPage.propTypes = {
  pageHeight: PropTypes.number,
  mouseEnteredInnerScroll: PropTypes.func,
  mouseLeftInnerScroll: PropTypes.func,
  scroll: PropTypes.func,
  delayAnimation: PropTypes.bool,
};

SixthPage.defaultProps = {};

export default SixthPage;
