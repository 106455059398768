import React from 'react'
import styles from './FifthPage.module.css';
import PropTypes from 'prop-types';
import DFSVideo from './media/DFS-FIXED.mp4'
import TerrariumVideo from './media/Terrarium-FIXED.mp4'
import TerrariumImg from './media/terrarium.jpg'
import ClockVideo from './media/Clock.mp4'
import LogRegVideo from './media/LoginRegister.mp4'
import SortingSimulatorVideo from './media/SortingSimulator.mp4'
import Img1 from './media/accurate-removalist.jpg'
import Img2 from './media/ashri.jpg'
import Img3 from './media/adhoc.jpg'
import Img5 from './media/sdotayalon.jpg'
import Img6 from './media/ganorsport.jpg'
import Img7 from './media/aconline.jpg'
import Img8 from './media/reevent.jpg'
import Img9 from './media/watercan.jpg'
import Popup from './components/Popup/Popup';
import LazyLoad from 'react-lazy-load';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngular, faCss3, faHtml5, faReact, faWordpress, faJoomla, faMagento } from '@fortawesome/free-brands-svg-icons';

class FifthPage extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      pageHeight: this.props.pageHeight,
      isPopupOpen: false,
      isVideo: false,
      popupImage: undefined,
      popupContent: {
        title: '',
        paragraph: '',
        tags: []
      },
      showHeadline: true
    };
    this.FirstVideoRef = React.createRef();
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.onScroll = (e) => {
      if (e.currentTarget.scrollY > this.props.pageHeight / 32 * 4) {
        this.setState({ position: (this.props.pageHeight / 32 * 4 - e.currentTarget.scrollY) });
      } else if (e.currentTarget.scrollY < this.props.pageHeight / 32 * 2) {
        this.setState({ position: (e.currentTarget.scrollY - this.props.pageHeight / 32 * 2) });
      } else {
        this.setState({ position: 0 });
      }
    }
    this.projects = [
      [
        {
          src: {
            img: Img9
          },
          popupContent: {
            title: 'Smart water can',
            paragraph: 'Scalable automation system designed to monitor and automate indoor plants care.',
            tags: ['Node.js'],
            link: 'https://github.com/johnyzelba/water-can'
          },
          icons: [faAngular, faCss3, faHtml5]
        },
        {
          src: {
            video: LogRegVideo
          },
          popupContent: {
            title: 'Single Page Login / Registration',
            paragraph: 'A single page for logging in, registration and forgot password forms. CSS animations triggered by click events.',
            tags: ['React', 'Html5', 'Css3'],
            link: 'https://github.com/johnyzelba/login-register-forgotPass'
          },
          icons: [faReact, faCss3, faHtml5]
        }
      ],
      [
        {
          src: {
            video: DFSVideo
          },
          popupContent: {
            title: 'DFS Algorithm Simulator',
            paragraph: 'A simulator for DFS and BFS algorithems, using svg canvas. weighted graph algorithems will be added in the feature.',
            tags: ['Angular', 'Svg', 'Canvas', 'Html5', 'Css3'],
            link: 'https://github.com/johnyzelba/DFS-simulator'
          },
          icons: [faAngular, faCss3, faHtml5]
        },
        {
          src: {
            video: SortingSimulatorVideo
          },
          popupContent: {
            title: 'Sorting Simulator',
            paragraph: `A simple sorting simulator using famous algorithems incl 'Bubble sort', 'Selection sort', 'Insertion sort', 'Merge sort' and 'Quick sort'`,
            tags: ['React', 'Html5', 'Css3'],
            link: 'https://github.com/johnyzelba/sorting-simulator'
          },
          icons: [faReact, faCss3, faHtml5]
        }
      ],
      [
        {
          src: {
            video: TerrariumVideo,
            img: TerrariumImg
          },
          popupContent: {
            title: 'Orchid Terrarium Controller',
            paragraph: 'Angular + Node.js + Raspberry pi implementation of an automated orchid terrarium controller.',
            tags: ['Node.js', 'Angular', 'Raspberry pi', 'WebSockets', 'Html5', 'Css3'],
            link: 'https://github.com/johnyzelba/Terrarium-Climate-Control-master'
          },
          icons: [faAngular, faCss3, faHtml5]
        },
        {
          src: {
            video: ClockVideo
          },
          popupContent: {
            title: 'SVG Animation',
            paragraph: 'A small project for a better understanding of animation capabilities and SVG concepts.',
            tags: ['Angular', 'SVG', 'Html5', 'Css3'],
            link: 'https://github.com/johnyzelba/Just-Another-Clock'
          },
          icons: [faAngular, faCss3, faHtml5]
        }
      ],
      [
        {
          src: {
            img: Img1
          },
          popupContent: undefined,
          icons: [faJoomla, faCss3, faHtml5]
        },
        {
          src: {
            img: Img2
          },
          popupContent: undefined,
          icons: [faWordpress, faCss3, faHtml5]
        }
      ],
      [
        {
          src: {
            img: Img5
          },
          popupContent: undefined,
          icons: [faWordpress, faCss3, faHtml5]
        },
        {
          src: {
            img: Img6
          },
          popupContent: undefined,
          icons: [faWordpress, faCss3, faHtml5]
        }
      ],
      [
        {
          src: {
            img: Img7
          },
          popupContent: undefined,
          icons: [faMagento, faCss3, faHtml5]
        },
        {
          src: {
            img: Img8
          },
          popupContent: undefined,
          icons: [faJoomla, faCss3, faHtml5]
        }
      ],
      [
        {
          src: {
            img: Img3
          },
          popupContent: undefined,
          icons: [faJoomla, faCss3, faHtml5]
        },
        {
          src: {
            html: <div className={styles.GalleryContactMe} onClick={() => {
              this.props.mouseLeftInnerScroll(); 
              setTimeout(() => {
                this.props.scroll('up');
              }, 0);
            }}>Contact me..</div>
          },
          popupContent: undefined,
          disableHoverBtn: true,
          icons: []
        }
      ]
    ];
  }

  handleClosePopup() {
    this.setState({ isPopupOpen: false, popupImage: undefined });
    this.props.mouseLeftInnerScroll();
  }

  componentDidMount() {
    this.setState({ pageHeight: this.props.pageHeight });
    window.addEventListener('scroll', this.onScroll);
  }

  render() {
    return (
      <div className={styles.ThirdPage} id="fifth-page">
        <Popup
          showPopup={this.state.isPopupOpen}
          popupImage={this.state.popupImage}
          popupContent={this.state.popupContent}
          isVideo={this.state.isVideo}
          closePopup={this.handleClosePopup}

        />
        <div className={styles.NavigatorContainer} style={{
          visibility: this.state.position === 0 ? 'visible' : 'hidden',
          opacity: this.state.position === 0 ? 1 : 0,
          transitionDelay: this.state.position === 0 ? '1s' : '0s',
        }}
        >
          <div className={styles.Navigator}>
            <div className={styles.ForwardContainer} onClick={() => this.props.scroll('up')}>Contact me! {`>`}</div>
            <div className={styles.BackwardContainer} onClick={() => this.props.scroll('down')}>{`<`} My experience</div>
          </div>
        </div>
        <div className={styles.EffectsContainer} style={{ left: this.state.position }} >
          <div
            className={styles.PortfolioContainer}
            onMouseEnter={() => {
              this.setState({ showHeadline: false });
              this.props.mouseEnteredInnerScroll();
            }}
            onMouseLeave={() => {
              this.setState({ showHeadline: true });
              this.props.mouseLeftInnerScroll();
            }}
          >
            {this.projects.map((projectRow, index) =>
              <div className={styles.RowContainer} key={`project-row-${index}`}>
                {projectRow.map((project, pIndex) => <div className={styles.PortfolioItem} key={`project-${index}-${pIndex}`}>
                  <LazyLoad
                    height='100%'
                  >
                    {project.src.video
                      ? <video style={{ width: '100%', height: '100%', objectFit: 'cover' }} autoPlay loop muted>
                        <source src={project.src.video} type="video/mp4" />
                      </video>
                      : project.src.img
                        ? <img src={project.src.img} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        : project.src.html
                    }

                  </LazyLoad>
                  {!project.disableHoverBtn ?
                    <div className={styles.HoverOnVideo}>
                      <div
                        className={styles.HoverBtn}
                        onClick={() => {
                          this.setState({
                            isPopupOpen: true,
                            isVideo: !!project.src.video,
                            popupImage: project.src.img || project.src.video,
                            popupContent: project.popupContent
                          });
                          this.props.mouseEnteredInnerScroll();
                        }
                        }
                      >
                        {project.icons.map((icon, index) => <FontAwesomeIcon key={`icon-${index}`} icon={icon} />)}
                        <span>|</span>
                        VIEW PROJECT
                      </div>
                    </div>
                    : <></>
                  }
                </div>)}
              </div>
            )}
          </div>

          <div className={styles.ContentContainer} style={{ visibility: this.state.position === 0 ? 'visible' : 'hidden', opacity: this.state.position === 0 ? 1 : 0 }}>
            <h4 style={{
              opacity: this.state.position === 0 && this.state.showHeadline ? 1 : 0,
              transform: this.state.position === 0 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20%, 0)',
              display: this.state.showHeadline ? 'flex' : 'none'
            }}>
              <span className='CharEffect'>P</span>
              <span className='CharEffect'>o</span>
              <span className='CharEffect'>r</span>
              <span className='CharEffect'>t</span>
              <span className='CharEffect'>f</span>
              <span className='CharEffect'>o</span>
              <span className='CharEffect'>l</span>
              <span className='CharEffect'>i</span>
              <span className='CharEffect'>o</span>
              <div className={styles.LineContainer} ><div className={styles.Line} /></div>
            </h4>
          </div>
        </div>
      </div>
    )
  }
}

FifthPage.propTypes = {
  pageHeight: PropTypes.number,
  mouseEnteredInnerScroll: PropTypes.func,
  mouseLeftInnerScroll: PropTypes.func,
  scroll: PropTypes.func,
};

FifthPage.defaultProps = {};

export default FifthPage;
