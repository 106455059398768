import React, { lazy, Suspense } from 'react';

const LazySpeechBubble = lazy(() => import('./SpeechBubble'));

const SpeechBubble = props => (
  <Suspense fallback={null}>
    <LazySpeechBubble {...props} />
  </Suspense>
);

export default SpeechBubble;
