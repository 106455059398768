import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import styles from './ShootingStar.module.css';

const ShootingStar = () => {
  const [number, setNumber] = useState(0);
  const [visible, setVisible] = useState(false);
  // add side effect to component
  useEffect(() => {
    // create interval
    const interval = setInterval(
      // set number every 5s
      async () => {
        let tempNumber = Math.floor(Math.random() * 100);
        setNumber(tempNumber)
        setVisible(false);
        await setInterval(() => {
          setVisible(true);
        }, 4000);
      },
      10000 + number * 500
    );

    // clean up interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      {visible ?
        <div className={styles.ShootingStarContainer} style={{ left: `${number}%` }}>
          <div className={styles.ShootingStar}>
          </div>
        </div>
        : <></>
      }
    </>
  )
};

ShootingStar.propTypes = {};

ShootingStar.defaultProps = {};

export default ShootingStar;
