import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loading.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
class Loading extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={
        styles.Loading
      }
        style={
          {
            visibility: this.props.loading ? 'visible' : 'hidden',
            opacity: this.props.loading ? 1 : 0
          }
        }
      >
        <FontAwesomeIcon icon={faRocket} className={styles.LoadingIcon} size="3x"/>
      </div>
    )
  }
}

Loading.propTypes = {
  loading: PropTypes.bool
};

Loading.defaultProps = {};

export default Loading;